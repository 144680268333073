@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&family=Outfit:wght@100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

.container {
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    height: 100vh;
}

.name {
    display: block;
    font-family: "Ubuntu", system-ui;
    font-weight: 800;
    font-size: 2.5rem;
    background: linear-gradient(45deg, #bfe9ff, #D26DCF);

    /* 确保文字颜色透明，让背景渐变显示出来 */
    -webkit-text-fill-color: transparent;

    /* 裁剪背景至文字 */
    -webkit-background-clip: text;
    background-clip: text;
}

.role {
    font-family: "Ubuntu", system-ui;
    display: block;
    font-weight: 600;
    font-size: 1.2rem;
    color: #1A2C5E;
}

.left {
    flex-direction: column;
    /* 垂直排列子元素 */
    display: flex;
    flex: 0 0 50%;
    padding: 2rem;
    align-items: center;
}

.right {
    margin-top: 2.5rem;
    position: relative;
    flex: 0 0 50%;
    text-align: center;
    padding: 2rem;
}

.myphoto {
    width: 76%;
    border-radius: 10%;
}

.see {
    margin-top: 0.5rem;
    font-family: "Ubuntu", system-ui;
    font-weight: 800;
    color: #1A2C5E;
    cursor: pointer;
}

.float {
    display: flex;
}

.program {
    width: 10%;
    left: 0;
    top: 0;
}

.carousel {
    font-family: "Ubuntu", system-ui;
    width: 20rem;
    font-weight: 400;
    margin-top: 2rem;
    text-align: center;
    color: #1A2C5E;
}

.jargon {
    line-height: 0.2rem;
}

.recenthighlights {
    margin-bottom: 0.5rem;
}

.show {
    display: flex;
    /* 显示时使用 flex 布局，确保内容居中 */
    opacity: 1;
    /* 透明度为1 */
}

.box {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    /* 默认隐藏 */
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: red;
    transition: opacity 0.5s ease-in-out;
    /* 添加过渡效果 */
    opacity: 0;
}



@media (max-width: 576px) {

    .container {
        display: flex;
        flex-direction: column-reverse !important;
        /* 设置为列方向布局 */
        margin-left: 5%;
        height: auto !important;
    }

    .myphoto {
        width: 100% !important;
        /* margin-bottom: -40rem !important; */
    }

    .right {
        margin-top: 0 !important;
        width: 90%;
        padding: 0 0 1rem 0 !important;
        /* 只保留底部一点填充 */
        margin-bottom: 0 !important;
        /* 取消底部外边距 */
        height: auto !important;
        /* 让高度根据内容自动调整 */
        flex: 0 !important;
    }

    .left {
        flex: 0 !important;
        align-items: flex-start !important;
        padding: 0 !important;
    }

    .carousel {
        margin-top: 1rem !important;
    }

}