@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

.container {
    display: flex;
    /* 使用flex布局 */
    justify-content: center;
    /* 水平居中 */
    align-items: center;
    color: #1A2C5E;
}

.nav {

    font-family: "Ubuntu", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-size: 2.5;
    color: aqua;
    margin-left: 4rem;
    display: flex;
    /* 使用flex布局 */
    justify-content: center;
    /* 水平居中 */
    align-items: center;
    /* 垂直居中 */
    gap: 2em;
}

/* 
.links {
    text-align: center;
} */

.name {
    font-family: "Ubuntu", sans-serif;
    font-weight: 800;
    font-size: 2rem;
    opacity: 0.7;
    color: #1A2C5E;
    margin-left: 2rem;
    margin-right: 10rem;
}

.chinesename {
    font-size: 0.8rem;
}

.inside {
    display: none;
}


.ins:hover {
    transform: scale(1.3);
    /* 鼠标悬停时放大图片 */
}

.social {
    text-align: center;
}

.brand {
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    /* Enables Flexbox */
    flex-direction: column;
    /* Stacks items vertically */
    align-items: center;
    /* Centers items horizontally */
    justify-content: center;
    /* Centers items vertically */
    height: 100%;
    /* Ensure the container fills the height of the Navbar */
}

.brandName {
    font-size: 0.8rem !important;
}

.brandContainer {
    display: flex;
    /* Aligns items on the same line */
    align-items: center;
    /* Centers items vertically */
    gap: 10px;
    /* Adjusts spacing between image and text */
}

/* navbar.module.css */

/* 原有的样式保留在这里 */

/* 媒体查询：屏幕宽度小于500像素时 */
@media (max-width: 576px) {
    .brandName {
        font-size: 0.8rem !important;
        font-weight: 800 !important;
        color: #FCC527;
    }

    .brand {
        width: 1rem !important;
        height: 1rem !important;
    }

    .brandContainer {
        gap: 0.4rem !important;
        width: 100%;
    }

    .name {
        margin-right: 0rem !important;
    }

    .nav {
        font-family: "Workbench", sans-serif;
        font-optical-sizing: auto;
        font-weight: 700;
        font-style: normal;
        font-size: 2.5rem;
        color: aqua;
        margin-left: 0 !important;
        display: flex;
        /* 使用flex布局 */
        justify-content: center;
        /* 水平居中 */
        align-items: center;
        /* 垂直居中 */
        gap: 1em;
    }

    .inside {
        display: block;
    }

    .outside {
        display: none;
    }
}