.footer {
    background-color: rgba(255, 255, 255, 0.1);
    /* 白色背景，透明度为0.8 */
    padding: 1rem 0;
    margin-top: 2rem;
    border-top: 1px solid #eaeaea;
    color: #1A2C5E;
}

.footer p {
    margin: 0.5rem 0;
}

.footer a {
    color: #007bff;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}