.container {
    display: flex;
    flex-direction: flex;
    justify-content: center;
    align-items: center;
    /* 让容器占满整个视口高度，确保子元素可以上下居中 */
}

.box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.picFrame {
    width: 100%;
    height: 100%;
    /* 垂直排列子元素 */
    display: flex;
    flex: 0 0 50%;
    justify-content: flex-end;
}

.pic {
    width: 40%;
    border-radius: 50%;
}

.text {
    flex: 0 0 50%;
    align-items: flex-start;
    cursor: pointer;
}

.icon {
    margin-left: 2rem;
    width: 5%;
}

.email {
    color: #0163CC;
    font-weight: 700;
}

.phone {
    font-weight: 700;
    color: #1A2C5E;
}

.phoneIcon {
    margin-left: 3rem;
    width: 3%;
}

@media (max-width: 576px) {
    .container {
        display: flex;
        flex-direction: column !important;
    }

    .picFrame {
        width: 100%;
        height: 100%;
        /* 垂直排列子元素 */
        display: flex;
        flex: 0 0 0 !important;
        justify-content: center !important;
    }

    .icon {
        margin-left: 0rem !important;
        width: 5%;
    }

    .text {
        margin-top: 2rem;
        align-items: center !important;
        text-align: center;
    }

    .phoneIcon {
        margin-left: 0 !important;
        width: 3%;
    }
}